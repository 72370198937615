const business = [
  {
    path: "/home",
    name: "HomeView",
    meta: { title: '首页' },
    component: () => import("@/views/Home"),
  },
  {
    path: "/enterprise",
    name: "EnterpriseView",
    meta: { title: '我是企业/我是服务商' },
    component: () => import("@/views/EnterpriseView"),
  },
  {
    path: "/specialize",
    name: "SpecializeView",
    meta: { title: '专精特新' },
    component: () => import("@/views/SpecializeView"),
  },
  {
    path: "/digitalize",
    name: "DigitalizeView",
    meta: { title: '智改数转' },
    component: () => import("@/views/DigitalizeView"),
  },
  {
    path: "/digitalizeDetail",
    name: "DigitalizeDetail",
    meta: { fatherPath: '/digitalize' },
    component: () => import("@/views/DigitalizeView/Detail"),
  },
  {
    path: "/provider",
    name: "ProviderView",
    meta: { title: '明星服务商' },
    component: () => import("@/views/ProviderView"),
  },
  {
    path: "/providerDetail",
    name: "ProviderDetail",
    component: () => import("@/views/ProviderView/Detail"),
    // component: () => import("@/views/ProviderView/Detail/static.vue"),
  },
  {
    path: "/experts",
    name: "ExpertsView",
    meta: { title: '服务专家' },
    component: () => import("@/views/ExpertsView"),
  },
  {
    path: "/expertsDetail",
    name: "ExpertsDetail",
    // meta: { fatherPath: '/experts' },
    component: () => import("@/views/ExpertsView/Detail"),
  },
  {
    path: "/demand",
    name: "DemandView",
    meta: { title: '服务需求' },
    component: () => import("@/views/DemandView"),
  },
  {
    path: "/demandDetail",
    name: "DemandDetail",
    meta: { fatherPath: '/demand' },
    component: () => import("@/views/DemandView/Detail"),
  },
  {
    path: "/supplyDemand",
    name: "SupplyDemand",
    meta: { title: '需求大厅' },
    component: () => import("@/views/SupplyDemand"),
  },
  {
    path: "/supplyDemandDetail",
    name: "SupplyDemandDetail",
    meta: { fatherPath: '/supplyDemand' },
    component: () => import("@/views/SupplyDemand/Detail"),
  },
  {
    path: "/servers",
    name: "ServersView",
    meta: { title: '数据服务' },
    component: () => import("@/views/ServersView"),
  },
  {
    path: "/serversDetail",
    name: "ServersDetail",
    meta: { fatherPath: '/servers' },
    component: () => import("@/views/ServersView/Detail"),
  },
  {
    path: "/serversPreview",
    name: "ServersPreview",
    meta: { fatherPath: '/servers' },
    component: () => import("@/views/ServersView/Detail/preview.vue"),
  },
  {
    path: "/activity",
    name: "ActivityView",
    meta: { title: '企服活动' },
    component: () => import("@/views/ActivityView"),
    redirect: '/activity/activityCalendar',
    children: [
      {
        path: "/activity/activityCalendar",
        name: "ActivityCalendar",
        meta: { title: '活动日历', fatherPath: '/activity' },
        component: () => import("@/views/ActivityView/ActivityCalendar.vue"),
      },
      {
        path: "/activity/activitySearch",
        name: "ActivitySearch",
        meta: { title: '活动检索', fatherPath: '/activity' },
        component: () => import("@/views/ActivityView/ActivitySearch.vue"),
      }
    ]
  },
  {
    path: "/activityDetail",
    name: "ActivityDetail",
    component: () => import("@/views/ActivityView/Detail"),
  },
  {
    path: "/caseCenter",
    name: "CaseCenterView",
    meta: { title: '案例中心' },
    component: () => import("@/views/CaseCenterView"),
  },
  {
    path: "/caseDetail",
    name: "CaseDetail",
    component: () => import("@/views/CaseCenterView/Detail"),
  },
  {
    path: "/coupon",
    name: "CouponView",
    component: () => import("@/views/CouponView")
  },
  {
    path: "/shopCart",
    name: "ShopCartView",
    meta: { title: '购物车' },
    component: () => import("@/views/ShopCartView")
  },
  {
    path: "/pay",
    name: "Pay",
    meta: { title: '支付' },
    component: () => import("@/views/PayView")
  }
]

export default business;