import Vue from "vue";
import VueRouter from "vue-router";
import business from './business'

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   redirect: '/'
  // },
  {
    path: "/",
    component: () => import("@/views/index.vue"),
    redirect: '/home',
    children: [...business]
  },
  {
    path: "/login",
    name: "LoginView",
    meta: { title: '登录' },
    component: () => import("@/views/LoginView"),
  },
  {
    path: "/register",
    name: "RegisterView",
    meta: { title: '注册' },
    component: () => import("@/views/RegisterView"),
  },
  {
    path: "/supplierAllBack",
    name: "SupplierAllBack",
    component: () => import("@/views/supplierAllBack.vue"),
  },
  { path: "*", redirect: "/404" },
  {
    path: "/404",
    meta: { title: '404页面错误' },
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // 页面刷新回滚顶部
    return { x: 0, y: 0 }
  },
});

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

export default router;
