/**
 * 用户数据接口
 */
import commonParam from '@/param/commonParam';
import interceptor from '@/server/interceptor';

const API = {
  PUBLIC_KEY_URL: commonParam.getPublicKeyUrl,
  USER_LOGIN_URL: '/uaa/authLogin',
  USER_LOGIN_MOBILE_URL: '/pub/sms/sendLoginSMSVerCode',
  USER_INFO_URL: '/auth/authUser/currentInfo',
  USER_PROVIDER_INFO_URL: '/pub/pubEnterpriseInfo/currentInfo',
  CHECK_REVIEW_STATUS_URL: '/pub/pubEnterpriseInfo/checkEntIsAudit',
  DEMAND_REQUIRE_URL: '/zh/zhServiceRequirementResponse/respond',
  PROVIDER_CONSULT_URL: '/zh/zhConsultation/save',
  ACTIVITY_ENROLL_URL: '/zh/zhActivityJoin/save',
  SERVICE_APPLY_URL: '/zh/zhItemApply/save',
  SERVICE_CONSULT_URL: '/zh/zhFeedbackItem/save',
  DEMAND_INFO_ANSWER_URL: '/zh/zhDemandInfoAnswer/save',
  COUPON_GET_URL: '/zh/zhCouponGet/drawPublic',
  COUPON_EXCHANGE_URL: '/zh/zhCouponGet/drawPrivate',
  SHOP_CART_ADD_URL: '/zh/zhShopCart/addCart',
  SHOP_CART_LIST_URL: '/zh/zhShopCart/listByCurrent',
  SHOP_CART_REMOVE_URL: '/zh/zhShopCart/remove',
  SHOP_CART_REMOVE_BATCH_URL: '/zh/zhShopCart/removeBatch',
  SHOP_CART_REMOVE_ALL_URL: '/zh/zhShopCart/removeAll',
  SHOP_CART_PURCHASE_URL: '/zh/zhShopCart/purchase',
  ORDER_INFO_URL: '/zh/zhItemApply/wxPayNative?id=',
  CHECK_WX_PAY_URL: '/zh/zhItemApply/getPaySate?id=',
}

// 获取加密公钥
export const reqRsaCode = () => interceptor.get(API.PUBLIC_KEY_URL);
// 用户登录
export const reqUserLogin = (dataInfo) => interceptor.post(API.USER_LOGIN_URL, dataInfo);
// 用户手机登录
export const reqUserLoginMobile = (mobile) => interceptor.post(API.USER_LOGIN_MOBILE_URL + `?mobile=${mobile}`);
// 用户信息
export const reqUserInfo = () => interceptor.get(API.USER_INFO_URL, { needToken: true });
// 用户企业信息
export const reqProviderInfo = () => interceptor.get(API.USER_PROVIDER_INFO_URL, { needToken: true });
// 检查当前企业审核状态
export const reqCheckReviewStatus = () => interceptor.get(API.CHECK_REVIEW_STATUS_URL, { needToken: true });
// 服务需求响应
export const reqDemandRequire = (obj) => interceptor.post(API.DEMAND_REQUIRE_URL, obj, { needToken: true });
// 提交对服务机构的咨询
export const reqProviderConsult = (obj) => interceptor.post(API.PROVIDER_CONSULT_URL, obj, { needToken: true });
// 活动报名
export const reqActivityEnroll = (obj) => interceptor.post(API.ACTIVITY_ENROLL_URL, obj, { needToken: true });
// 服务项目申请
export const reqServiceApply = (obj) => interceptor.post(API.SERVICE_APPLY_URL, obj, { needToken: true });
// 服务项目咨询
export const reqServiceConsult = (obj) => interceptor.post(API.SERVICE_CONSULT_URL, obj, { needToken: true });
// 商品需求响应
export const reqDemandInfoAnswer = (data) => interceptor.post(API.DEMAND_INFO_ANSWER_URL, data, { needToken: true });
/* 优惠券 */
// 领取优惠券
export const reqCouponGet = (couponId) => interceptor.post(API.COUPON_GET_URL + `?couponId=${couponId}`, {}, { needToken: true });
// 兑换购物券
export const reqCouponExchange = (privateCode) => interceptor.post(API.COUPON_EXCHANGE_URL + `?privateCode=${privateCode}`, {}, { needToken: true });
/* 购物车 */
// 购物车列表
export const reqCartList = () => interceptor.get(API.SHOP_CART_LIST_URL, { needToken: true });
// 添加购物车
export const reqAddCart = (itemId) => interceptor.post(API.SHOP_CART_ADD_URL + `?itemId=${itemId}`, {}, { needToken: true });
// 单个删除购物车商品
export const reqShopCartRemove = (id) => interceptor.post(API.SHOP_CART_REMOVE_URL + `?id=${id}`, {}, { needToken: true });
// 批量删除
export const reqShopCartRemoveBatch = (ids) => interceptor.post(API.SHOP_CART_REMOVE_BATCH_URL + `?ids=${ids}`, {}, { needToken: true });
// 清空购物车
export const reqShopCartRemoveAll = () => interceptor.post(API.SHOP_CART_REMOVE_ALL_URL, {}, { needToken: true });
// 商品结算
export const reqShopCartPurchase = (data) => interceptor.post(API.SHOP_CART_PURCHASE_URL, data, { needToken: true });
// 获取订单详情
export const reqOrderInfo = (id) => interceptor.post(API.ORDER_INFO_URL + id, {}, { needToken: true });
// 
export const reqCheckWXPay = (id) => interceptor.get(API.CHECK_WX_PAY_URL + id, {needToken: true})