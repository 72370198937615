import ax from "axios";
import { Message } from 'element-ui';

const instance = ax.create({
  timeout: 30000,
});

// 请求拦截器
instance.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error)
});

// 响应拦截器
instance.interceptors.response.use(async (response) => {
  return response.data;
}, (error) => {
  const { status } = error.response;
  switch (status) {
    case 401:
      Message.error('请求参数错误');
      break
    case 404:
      Message.error('请求失败或路径错误');
      break
    case 500 || 501 || 502 || 503 || 504 || 505:
      Message.error('服务器出现问题，请联系管理员');
      break
    default:
      Message.error(error.message);
      break
  }
  return Promise.reject(error);
});

export default instance;
