import commonParam from "@/param/commonParam";
import jq from "jquery"

/**
 * 获取或刷新token
 * @returns {null|*}
 */
export function getOrRefreshToken() {
    let tokenTime = Number.parseInt(localStorage.getItem(commonParam.tokenStorageTime) ?? "0");
    let nowTime = new Date().getTime();
    let spaceTime = nowTime - tokenTime;
    let tokenInfo = JSON.parse(localStorage.getItem(commonParam.tokenStorageName) ?? "{}");
    // console.log('space time : ', spaceTime)
    // console.log('token out time : ', commonParam.tokenOutTime)
    if (spaceTime > commonParam.tokenOutTime && spaceTime < commonParam.refreshTokenOutTime) {
        return refreshToken(tokenInfo)
        //return tokenInfo.access_token;
    } else if (spaceTime > commonParam.refreshTokenOutTime) {
        return null;
    } else {
        return tokenInfo.access_token;
    }
}

/**
 * token刷新
 * @param tokenInfo
 * @returns {null}
 */
function refreshToken(tokenInfo) {
    // console.log("-----------");
    // console.log(tokenInfo);
    // console.log("-----------");
    let dataInfo = { "grant_type": "refresh_token", "refresh_token": tokenInfo.refresh_token }
    let refreshInfo = {}
    jq.ajax({
        async: false,
        type: "post",
        url: commonParam.serverUrl + commonParam.refreshTokenUrl,
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify(dataInfo),
        success: function (resp) {
            if (resp.result) {
                refreshInfo = resp.data;
            }
        }
    });
    localStorage.setItem(commonParam.tokenStorageName, JSON.stringify(refreshInfo))
    localStorage.setItem(commonParam.tokenStorageTime, new Date().getTime())
    return refreshInfo.access_token;
}