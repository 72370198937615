import { reqUserInfo, reqProviderInfo, reqCartList } from "@/api/user";
import commonParam from "@/param/commonParam";
import { EXPIRES_IN, REFRESH_TOKEN, SHOP_CART, TOKEN, TOKEN_TIME, USER_INFO, USER_PROVIDER_INFO } from "@/param/method_param";
import { getData, setData, delData } from "@/utils/localStoreUtil";

const personOptions = {
  namespaced: true,
  state: {
    // 
    token: getData(TOKEN) || null,
    refreshToken: getData(REFRESH_TOKEN) || null,
    // token有效时间
    expires_in: getData(EXPIRES_IN) || null,
    // 用户登录时间戳
    tokenTime: getData(TOKEN_TIME) || null,
    // 用户信息
    userInfo: getData(USER_INFO) || null,
    // 用户企业信息
    userProviderInfo: getData(USER_PROVIDER_INFO) || null,
    // 购物车
    shopCart: getData(SHOP_CART) || [],
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SER_REFRESH_TOKEN(state, value) {
      state.refreshToken = value;
    },
    SET_EXPIRES_IN(state, value) {
      state.expires_in = value;
    },
    SET_TOKEN_TIME(state, value) {
      state.tokenTime = value;
    },
    SET_USER_INFO(state, value) {
      state.userInfo = value;
    },
    SET_USER_PROVIDER_INFO(state, value) {
      state.userProviderInfo = value;
    },
    SET_SHOP_CART(state, value) {
      state.shopCart = value;
    },
    SET_RESET_STATE(state, value) {
      state.token = value;
      state.refreshToken = value;
      state.expires_in = value;
      state.tokenTime = value;
      state.userInfo = value;
      state.userProviderInfo = value;
    }
  },
  actions: {
    /* 用户登录 */
    userLogin(context, value) {
      const { access_token, refresh_token, expires_in } = value;
      const tokenTime = new Date().getTime();
      const expiresInTime = expires_in * 1000;
      setData(TOKEN, access_token);
      setData(REFRESH_TOKEN, refresh_token);
      setData(EXPIRES_IN, expiresInTime);
      setData(TOKEN_TIME, tokenTime);
      context.commit('SET_TOKEN', access_token);
      context.commit('SER_REFRESH_TOKEN', refresh_token);
      context.commit('SET_EXPIRES_IN', expiresInTime);
      context.commit('SET_TOKEN_TIME', tokenTime);
    },
    /* 获取用户信息 */
    async getUserInfo(context, _) {
      const resp = await reqUserInfo();
      if (resp.result) {
        setData(USER_INFO, resp.data);
        context.commit('SET_USER_INFO', resp.data);
      }
    },
    /* 获取用户企业信息 */
    async getUserProviderInfo(context, _) {
      const resp = await reqProviderInfo();
      if (resp.result) {
        setData(USER_PROVIDER_INFO, resp.data);
        context.commit('SET_USER_PROVIDER_INFO', resp.data);
      }
    },
    async getShopCart(context, _) {
      const resp = await reqCartList();
      if (resp.result) {
        setData(SHOP_CART, resp.data);
        context.commit('SET_SHOP_CART', resp.data);
      }
    },
    /* 加入购物车 */
    setShopCart(context, value) {
      setData(SHOP_CART, value);
      context.commit('SET_SHOP_CART', value);
    },
    /* 重置缓存数据 */
    setResetState(context, value) {
      delData(TOKEN);
      delData(REFRESH_TOKEN);
      delData(EXPIRES_IN);
      delData(TOKEN_TIME);
      delData(USER_INFO);
      delData(USER_PROVIDER_INFO);
      localStorage.removeItem(commonParam.tokenStorageName);
      localStorage.removeItem(commonParam.tokenStorageTime);
      context.commit('SET_RESET_STATE', value);
    }
  },
  getters: {
    /* 判断用户登录 */
    _isLogin(state) {
      const result = state.token ? true : false;
      return result;
    },
    /* 判断登录超时 */
    _isRefresh(state) {
      if (state.token && state.tokenTime) {
        const nowTime = new Date().getTime();
        if (nowTime - state.tokenTime < state.expires_in) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    _isUserBind(state) {
      const { token, userProviderInfo } = state;
      return token && userProviderInfo && Object.values(userProviderInfo).length > 0 ? true : false;
		}
  }
}

export default personOptions;
