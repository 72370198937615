<template>
  <el-skeleton class="list_item">
    <template slot="template">
      <el-row style="padding: 10px;" :gutter="0">
        <el-col :span="7">
          <el-skeleton-item class="img" variant="image" />
        </el-col>
        <el-col :span="17">
          <div class="content">
            <el-skeleton-item variant="p" style="width: 60%;" />
            <el-skeleton-item variant="p" />
            <el-skeleton-item variant="p" />
            <el-skeleton-item variant="p" style="width: 30%;" />
          </div>
        </el-col>
      </el-row>
    </template>
  </el-skeleton>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.list_item {
  margin-bottom: 20px;
  border: 1px solid var(--border-color-extra-light);
  border-radius: 6px;
  overflow: hidden;

  .img {
    width: 150px;
    height: 100px;
  }

  .content {
    line-height: 2;

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      &-text {
        width: 45%;
      }
    }
  }
}
</style>