<template>
  <div class="footer_container por_wrapper">
    <div class="content">
      <div>
        <div>地址：成都市金牛区成华街5号</div>
        <div>电话：028-83228797</div>
        <!-- <div>&copy; 2023-2024 益企云科技股份有限公司版权所有</div> -->
      </div>
      <div>
        <div>
          <a
            class="link"
            :href="systemRecordPath"
            target="_blank"
            rel="noopener noreferrer"
            >{{ systemRecord }}</a
          >
        </div>
        <div>&copy; 2023-2024 {{ systemCompany }}版权所有</div>
        <!-- <div>
          <a
            class="link"
            href="https://beian.mps.gov.cn/#/query/webSearch"
            target="_blank"
            rel="noopener noreferrer"
            >川公网安备51010602001543号</a
          >
        </div> -->
      </div>
      <div class="qrcode">
        <!-- <img
          src="https://www.smesc.cn/skin/new2021//css/img/qrcode.jpg"
          alt="qrcode"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import commonParam from "@/param/commonParam";
export default {
  name: "FooterComponent",
  data() {
    return {
      systemCompany: commonParam.systemCompany,
      systemRecord: commonParam.systemRecord,
      systemRecordPath: commonParam.systemRecordPath,
      smescPath: commonParam.smescPath,
    };
  },
};
</script>

<style lang="less" scoped>
.footer_container {
  color: #ddd;
  line-height: 2;
  display: flex;
  align-items: center;

  .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .qrcode {
      width: 90px;
    }

    .link {
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>