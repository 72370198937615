/**
 * 页面字典数据接口
 */
import interceptor from '@/server/interceptor';
import other from '@/server/other';

const API = {
  DISTRICT_URL: '/zh/dict/listDistrict',
  CUSTOM_SERVICE_CLASS_LIST_URL: '/sys/sysYqszServiceClass/listVoAll',
  SERVICE_CLASS_LIST_URL: '/sys/sysYqszServiceClass/listVoAll',
  SERVICE_MODE_LIST_URL: '/zh/dict/listServiceMode',
  SERVICE_OBJECT_LIST_URL: '/zh/dict/listServiceObject',
  USER_LOCAL_URL: 'https://restapi.amap.com/v3/ip?key=0916966bbe8231b8836b25ae013d6735&output=jsonp',
  PAGE_INFO_URL: '/sys/sysUserBrowseHistory/saveCache'
}

// 地区列表
export const reqDistrictList = () => interceptor.get(API.DISTRICT_URL);
// 益企数智系统服务分类列表
export const reqCustomServiceClassList = () => interceptor.get(API.CUSTOM_SERVICE_CLASS_LIST_URL);
// 系统服务分类列表
export const reqServiceClassList = () => interceptor.get(API.SERVICE_CLASS_LIST_URL);
// 系统服务方式列表
export const reqServiceModeList = () => interceptor.get(API.SERVICE_MODE_LIST_URL);
// 系统服务对象列表
export const reqServiceObjectList = () => interceptor.get(API.SERVICE_OBJECT_LIST_URL);
// 用户定位信息
export const reqUserLocal = () => other.get(API.USER_LOCAL_URL);

export const reqPageInfo = (data) => interceptor.post(API.PAGE_INFO_URL, data, { defaultToken: true });
