<template>
  <div class="empty-img" :style="imageStyle">
    <el-image :src="require(`@/assets/img/${img}`)" :fit="fit"></el-image>
    <p v-if="msg">{{ msg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String | Boolean,
      default: "暂无相关数据"
    },
    img: {
      type: String,
      default: "empty.png"
    },
    fit: {
      type: String,
      default: "fill"
    },
    imageStyle: {
      type: Object,
      default: () => {
        return {
          width: '350px',
          height: '350px'
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.empty-img {
  position: relative;
  // width: 350px;
  // height: 350px;
  margin: 0 auto;

  p {
    position: absolute;
    left: 50%;
    bottom: 20%;
    transform: translateX(-50%);
    font-size: 18px;
    color: #197dff;
    text-align: center;
  }
}
</style>