/**
 * 在请求拦截器中刷新token
 */
// 导入axios
import ax from "axios";
import { Message, MessageBox } from "element-ui";
import store from "@/store";
import router from "@/router/index";
import commonParam from "@/param/commonParam";
import { getOrRefreshToken } from "@/utils/tokenUtil";

const instance = ax.create({
  baseURL: commonParam.serverUrl,
  timeout: 30000,
});

// 是否正在刷新的标记
let isRefreshing = false;

/* 清空缓存 */
function cleanStorage() {
  if (!isRefreshing) {
    isRefreshing = true;
    store.dispatch('personOptions/setResetState', null)
    MessageBox.alert('登录已过期，请重新登录', '提示', {
      confirmButtonText: '确定',
      type: 'warning',
      showClose: false,
      callback: () => {
        router.push("/login");
        isRefreshing = false;
      }
    })
  }
}

// 请求拦截器
instance.interceptors.request.use((config) => {
  if (config.needToken) {
    const token = getOrRefreshToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
      // token 是否过期
    } else {
      cleanStorage();
      return Promise.reject('登录已过期，请重新登录');
    }
  }
  if (config.defaultToken) {
    const token = getOrRefreshToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(async (response) => {
  /* if (parseInt(response.data.code) === -200) {
    cleanStorage(response.data.message);
  } */
  return response.data;
}, (error) => {
  if (error.response) {
    const { status } = error.response;
    switch (status) {
      case 401:
        Message.error('请求参数错误');
        break
      case 404:
        Message.error('请求失败或路径错误');
        break
      case 500 || 501 || 502 || 503 || 504 || 505:
        Message.error('服务器出现问题，请联系管理员');
        break
      default:
        Message.error(error.message);
        break
    }
  } else {
    Message.error(error);
  }
  return Promise.reject(error); // 接口500抛出异常（不走页面逻辑）
});

export default instance;
