import { DISTRICT_LIST, SERVICE_CLASS_LIST, SERVICE_MODE_LIST, SERVICE_OBJECT_LIST, CUSTOM_SERVICE_CLASS_LIST, USER_LOCAL } from "@/param/method_param";
import { reqDistrictList, reqServiceModeList, reqServiceClassList, reqServiceObjectList, reqCustomServiceClassList, reqUserLocal } from "@/api/dictData";
import { getData, setData } from "@/utils/localStoreUtil";

const pageOptions = {
  namespaced: true,
  state: {
    // 弹出窗状态 显示/隐藏
    dialogVisible: false,
    // 搜索关键字
    searchKey: '',
    // 页面字典数据
    // 地区
    districtList: getData(DISTRICT_LIST) || null,
    // 益企数智系统服务分类列表
    customServiceClassList: getData(CUSTOM_SERVICE_CLASS_LIST) || null,
    // 系统服务分类
    serviceClassList: getData(SERVICE_CLASS_LIST) || null,
    // 系统服务方式
    serviceModeList: getData(SERVICE_MODE_LIST) || null,
    // 系统服务对象
    serviceObjectList: getData(SERVICE_OBJECT_LIST) || null,
    // 用户所属地区
    userLocal: getData(USER_LOCAL) || null
  },
  mutations: {
    SET_SEARCH_KEY(state, value) {
      state.searchKey = value;
    },
    SET_DIALOG_VISIBLE(state, value) {
      state.dialogVisible = value;
    },
    SET_DISTRICT_LIST(state, value) {
      state.districtList = value;
    },
    SET_CUSTOM_SERVICE_CLASS_LIST(state, value) {
      state.customServiceClassList = value;
    },
    SET_SERVICE_CLASS_LIST(state, value) {
      state.serviceClassList = value;
    },
    SET_SERVICE_MODE_LIST(state, value) {
      state.serviceModeList = value;
    },
    SET_SERVICE_OBJECT_LIST(state, value) {
      state.serviceObjectList = value;
    },
    SET_USER_LOCAL(state, value) {
      state.userLocal = value;
    }
  },
  actions: {
    /* 获取地区列表数据 */
    async getDistrictList(context, _) {
      // if (context.state.districtList) return;
      const resp = await reqDistrictList();
      if (resp.result) {
        setData(DISTRICT_LIST, resp.data);
        context.commit("SET_DISTRICT_LIST", resp.data);
      }
    },
    /* 获取益企数智系统服务分类列表数据 */
    async getCustomServiceClassList(context, _) {
      // if (context.state.customServiceClassList) return;
      const resp = await reqCustomServiceClassList();
      if (resp.result) {
        setData(CUSTOM_SERVICE_CLASS_LIST, resp.data);
        context.commit("SET_CUSTOM_SERVICE_CLASS_LIST", resp.data);
      }
    },
    /* 获取系统服务分类列表数据*/
    async getServiceClassList(context, _) {
      // if (context.state.serviceClassList) return;
      const resp = await reqServiceClassList();
      if (resp.result) {
        setData(SERVICE_CLASS_LIST, resp.data);
        context.commit("SET_SERVICE_CLASS_LIST", resp.data);
      }
    },
    /* 获取系统服务方式列表数据 */
    async getServiceModeList(context, _) {
      // if (context.state.serviceModeList) return;
      const resp = await reqServiceModeList();
      if (resp.result) {
        setData(SERVICE_MODE_LIST, resp.data);
        context.commit("SET_SERVICE_MODE_LIST", resp.data);
      }
    },
    /* 获取系统服务对象列表数据 */
    async getServiceObjList(context, _) {
      // if (context.state.serviceObjectList) return;
      const resp = await reqServiceObjectList();
      if (resp.result) {
        setData(SERVICE_OBJECT_LIST, resp.data);
        context.commit("SET_SERVICE_OBJECT_LIST", resp.data);
      }
    },
    /* 获取用户所在位置 */
    async getUserLocal(context, _) {
      // if (context.state.userLocal) return;
      const resp = await reqUserLocal();
      const { province, city, adcode } = resp;
      const provinceCode = adcode.slice(0, 2) !== '00' ? adcode.slice(0, 2) : '';
      const cityCode = adcode.slice(2, 4) !== '00' ? adcode.slice(0, 4) : '';
      const tempObj = { adcode, province, provinceCode, city, cityCode };
      setData(USER_LOCAL, tempObj);
      context.commit('SET_USER_LOCAL', tempObj);
    }
  },
  getters: {}
}

export default pageOptions;
