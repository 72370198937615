<template>
  <div class="header_container por_wrapper">
    <div class="left">
      <div class="logo_wrapper" @click="goHome">
        <img src="@/assets/img/logo.png" alt="logo" />
      </div>
      <div class="nav_wrapper">
        <div class="nav-list">
          <div
            class="nav-item"
            :class="{
              detail_ctive: _path === item.path,
              active_nav: current === item.path,
            }"
            :key="item.id"
            v-for="item in menuNav"
            @click="handleClick(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!_isLogin" class="btn_wrapper">
      <div class="shop-cart" @click="toShopCart">
        <el-badge
          v-if="badgeValue && badgeValue > 0"
          :value="badgeValue"
          :max="99"
          class="item"
        >
          <i class="icon fa fa-shopping-cart"></i>购物车&nbsp;
        </el-badge>
        <span v-else><i class="icon fa fa-shopping-cart"></i>购物车&nbsp;</span>
      </div>
      <div class="login_btn" @click="toLogin">
        <i class="icon fa fa-user-o" />登录
      </div>
      <div class="register_btn btn" @click="toRegister">
        <i class="icon fa fa-edit" />注册
      </div>
    </div>
    <div v-else class="btn_wrapper">
      <div class="shop-cart" @click="toShopCart">
        <el-badge
          v-if="badgeValue && badgeValue > 0"
          :value="badgeValue"
          :max="99"
          class="item"
        >
          <i class="icon fa fa-shopping-cart"></i>购物车&nbsp;
        </el-badge>
        <span v-else><i class="icon fa fa-shopping-cart"></i>购物车&nbsp;</span>
      </div>
      <div class="avatar" :title="userName">
        <el-dropdown
          trigger="click"
          placement="bottom"
          @command="handleCommand"
        >
          <el-avatar
            shape="square"
            :size="30"
            style="font-size: 16px; cursor: pointer"
          >
            {{ userName ? userName.substring(0, 1) : "" }}
          </el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="toAdmin">
              <i class="icon fa fa-user"></i>
              <span class="dropdown-text">个人中心</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              <i class="icon fa fa-sign-out"></i>
              <span class="dropdown-text">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import applicationList from "@/assets/js/applicationList";
import { warningMsg } from "@/utils/messageUtil";
import commonParam from "@/param/commonParam";
import { reqCartList } from "@/api/user";
import { deepClone } from "@/utils/dataUtil";
import { setData } from "@/utils/localStoreUtil";
import { SHOP_CART } from "@/param/method_param";
export default {
  name: "HeaderComponent",
  data() {
    return {
      menuNav: applicationList.menuList,
      dropdownList: applicationList.dropdownList,
      current: "/home",
      userName: "",
      toAdminUrl: "",
      paths: {
        homePath: "/",
        loginPath: "/login",
        registerPath: "/register",
        supplierAllBack: "/supplierAllBack",
        shopCart: "/shopCart",
      },
    };
  },
  computed: {
    _path() {
      const { fatherPath } = this.$route.meta;
      if (fatherPath) {
        const result = this.menuNav.find((item) => item.path === fatherPath);
        return result.path;
      } else {
        return "";
      }
    },
    badgeValue() {
      return this.$store.state.personOptions.shopCart.length;
    },
    ...mapState("personOptions", ["userInfo"]),
    ...mapGetters("personOptions", ["_isLogin"]),
  },
  methods: {
    // 初始化数据
    init() {
      this.current = this.$route.path;
      this.userName =
        this.userInfo && this.userInfo.userName ? this.userInfo.userName : "";
      let tokenInfo = JSON.parse(
        localStorage.getItem(commonParam.tokenStorageName) ?? "{}"
      );
      if (tokenInfo.refresh_token) {
        this.toAdminUrl = commonParam.adminUrl + tokenInfo.refresh_token;
      }
      if (this._isLogin) {
        this.initDataList();
      }
    },
    // 登录用户购物车数据
    initDataList() {
      reqCartList()
        .then((resp) => {
          if (resp.result) {
            const arrResp = deepClone(resp.data);
            arrResp.forEach((item) => {
              item.checked = false;
            });
            this.dataList = arrResp;
            this.total = arrResp.length;
            this.setShopCart(arrResp);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /* 加入购物车 */
    setShopCart(context, value) {
      setData(SHOP_CART, value);
      context.commit("SET_SHOP_CART", value);
    },
    // 前往首页
    goHome() {
      this.$router.push(this.paths.homePath);
    },
    // 前往登录页面
    toLogin() {
      this.$router.push(this.paths.loginPath);
    },
    // 前往注册页面
    toRegister() {
      this.$router.push(this.paths.registerPath);
    },
    // 前往购物车
    toShopCart() {
      this.$router.push(this.paths.shopCart);
    },
    // 导航菜单
    handleClick(item) {
      if (item.target) {
        window.open(item.path, true);
      } else {
        this.$router.push(item.path);
      }
    },
    handleCommand(command) {
      const result = this.dropdownList.find((item) => item.value === command);
      switch (command) {
        case "logout":
          this.logOutFn();
          break;
        case "toAdmin":
          window.open(this.toAdminUrl);
          break;
        default:
          if (result.isUse) {
            this.$router.push(result.path);
          } else {
            warningMsg(this, "尚未开放");
          }
          break;
      }
    },
    logOutFn() {
      this.$confirm("您确定要退出当前账户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "帐号退出中,请稍后!",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          // 清空缓存 重置vuex
          this.$store.dispatch("personOptions/setResetState", null);
          setTimeout(() => {
            loading.close();
            this.$router.push(this.paths.supplierAllBack);
          }, 1000);
        })
        .catch(() => {});
    },
    ...mapActions("personOptions", { setShopCart: "setShopCart" }),
  },
  created() {
    this.init();
  },
  watch: {
    $route(to, from) {
      this.current = to.path;
    },
  },
};
</script>

<style lang="less" scoped>
.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
  }

  .logo_wrapper {
    width: 150px;
    // height: 65px;
    cursor: pointer;
  }

  .nav_wrapper {
    font-size: 18px;
    padding-top: 10px;
    margin-left: 30px;

    .nav-list {
      display: flex;
      align-items: center;

      .nav-item {
        margin: 0 12px;
        color: #2c3e50;
        font-weight: 600;
        cursor: pointer;

        &.active_nav,
        &.detail_ctive {
          color: var(--text-color-orange);
        }
      }
    }
  }

  .btn_wrapper {
    display: flex;
    align-items: center;
    padding-top: 10px;

    .btn {
      cursor: pointer;
      padding: 7px 17px;
      border-radius: 30px;
      box-sizing: border-box;
      display: flex;
      align-items: baseline;
      border: 2px solid transparent;
    }

    .login_btn {
      cursor: pointer;
      margin-right: 15px;
      font-weight: 600;
    }

    .register_btn {
      color: #fff;
      border-color: var(--border-color-darker);
      background-color: var(--text-color-orange);
    }

    .shop-cart {
      cursor: pointer;
      font-weight: 600;
      margin-right: 15px;
    }

    .icon {
      margin-right: 5px;
    }
  }
}
</style>