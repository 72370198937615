// token
export const TOKEN = "token";
// 获取 token 时间戳
export const TOKEN_TIME = "tokenTime";
// token 有效时间
export const EXPIRES_IN = "expires_in";
// refresh_token
export const REFRESH_TOKEN = "refreshToken";
// token 过期时间
export const REFRESH_TOKEN_OVER_TIME = "refreshTokenOverTime";
// 用户菜单
export const USER_MENU = "userMenu";
// 用户信息
export const USER_INFO = "userInfo";
// 用户企业数据
export const USER_PROVIDER_INFO = "userProviderInfo";
// 地区
export const DISTRICT_LIST = "districtList";
// 行业
export const INDUSTRY = "industry";
// 字典类型
export const DICT_TYPE = "dict_type";
// 企业注册类型
export const ECON_TYPE = "econ_type";
// 企业控股类型
export const CTRL_TYPE = "ctrl_type";
// 主管部门
export const GOV = "gov";
// 字典数据
export const DICT_MAP = {
  CARDTYPE: "CARDTYPE",
};
// 系统参数版本（登录时验证是否需要重复获取参数）
export const SYSTEM_VERSION = "systemVersion";
// 用户菜单角色
export const MENU_ROLE = "menuRole";
// tabs - 导航栏
export const TABS = "tabs";
// breadcrumb - 面包屑标签
export const BREAD_CRUMB = "breadCrumb";
// 搜素关键字
export const SEARCH_KEY = "searchKey";
// 点击内容
export const CLICK_ITEM = "clickItem";
// 标签
export const TAG_DATA = "tagData";
// 年份
export const POLICY_YEAR = "policyYear";
// 供应类型
export const SUPPLY_TYPE = "supplyType";
// 需求类型
export const DEMAND_TYPE = "demandType";
// 服务类型
export const CUSTOM_SERVICE_CLASS_LIST = "customServiceClassList";
export const SERVICE_CLASS_LIST = "serviceClassList";
export const SERVICE_MODE_LIST = "serviceModeList";
export const SERVICE_OBJECT_LIST = "serviceObjectList";
// 用户定位
export const USER_LOCAL = "userLocal";
export const SHOP_CART = "shopCart";