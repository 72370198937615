import Vue from "vue";
import Vuex from "vuex";
import pageOptions from "./pageOptions";
import personOptions from "./personOptions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    pageOptions,
    personOptions,
  },
});
