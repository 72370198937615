import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Header from './components/Layout/Header.vue';
import Footer from './components/Layout/Footer.vue';
import Dialog from './components/Layout/Dialog.vue';
import PublicLocal from './components/Layout/PublicLocal.vue';
import Filter from './components/Filter/index.vue';
import EmptyImg from './components/emptyImg.vue';
import Pagination from './components/Pagination/index.vue';
import SkeletonCard from './components/Skeleton/skeletonCard.vue';
import SkeletonLand from './components/Skeleton/skeletonLand.vue';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/iconfont/iconfont.css';
import './assets/css/reset.css';
import './assets/css/public.css';
import './assets/css/el.css';
import './permisssion';

Vue.config.productionTip = false;

// 引入全局组件
Vue.component('HeaderComponent', Header);
Vue.component('FooterComponent', Footer);
Vue.component('DialogComponent', Dialog);
Vue.component('LocalComponent', PublicLocal);
Vue.component('FilterComponent', Filter);
Vue.component('EmptyImg', EmptyImg);
Vue.component('PaginationComponent', Pagination);
Vue.component('SkeletonCard', SkeletonCard);
Vue.component('SkeletonLand', SkeletonLand);

// 引入element框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { size: 'small' });

/* var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?16d8605716fe86fea368b8184ac2f79c";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})(); */

new Vue({ router, store, render: (h) => h(App) }).$mount('#app');
