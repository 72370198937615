/* 路由鉴权 */
import router from './router';
// 引入进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// import { reqPageInfo } from './api/dictData';
// const domainName = 'https://yqsz.smesc.cn/#';

// 进度条配置
NProgress.configure({ showSpinner: false });

// 路由守卫
router.beforeEach((to, from, next) => {
  NProgress.start();
  // 页面标签名称
  document.title = to.meta.title ? to.meta.title + '-益企云 企业服务数字化平台' : '益企云 企业服务数字化平台';
  /* const dataInfo = {
    url: domainName + to.fullPath,
    title: document.title,
    systemCode: '020',
    deviceType: 'PC'
  }
  reqPageInfo(dataInfo);
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  } */
  next();
})

router.afterEach(() => {
  NProgress.done();
})