/**
 * element 消息提示 方法
 */

const DEFAULT_SUCCESS_TITLE = "成功";
const DEFAULT_SUCCESS_MSG = "操作成功！";
const DEFAULT_ERROR_TITLE = "失败";
const DEFAULT_ERROR_MSG = "操作失败";
const DEFAULT_WARNING_TITLE = "警告";
const DEFAULT_WARNING_MSG = "当前操作异常！";
const DEFAULT_INFO_TITLE = "提示";
const DEFAULT_INFO_MSG = "已经执行当前操作！";

/**
 * @description notify 成功提示
 * @param {*} that this
 * @param {String} msg 提示文字
 * @param {String} title 提示标题
 */
export const successNotify = (that, msg, title) => {
  let t = title ? title : DEFAULT_SUCCESS_TITLE;
  let m = msg ? msg : DEFAULT_SUCCESS_MSG;
  that.$notify({
    title: t,
    message: m,
    type: "success",
    showClose: true,
  });
};

/**
 * @description message 成功提示
 * @param {*} that this
 * @param {String} msg 提示文字
 */
export const successMsg = (that, msg) => {
  let m = msg ? msg : DEFAULT_SUCCESS_MSG;
  that.$message({
    message: m,
    type: "success",
    showClose: true,
  });
};

/**
 * @description notify 失败提示
 * @param {*} that this
 * @param {String} msg 提示文字
 * @param {String} title 提示标题
 */
export const errorNotify = (that, msg, title) => {
  let t = title ? title : DEFAULT_ERROR_TITLE;
  let m = msg ? msg : DEFAULT_ERROR_MSG;
  that.$notify.error({
    title: t,
    message: m,
    showClose: true,
  });
};

/**
 * @description message 失败提示
 * @param {*} that this
 * @param {String} msg 提示文字
 */
export const errorMsg = (that, msg) => {
  let m = msg ? msg : DEFAULT_ERROR_MSG;
  that.$message({
    message: m,
    type: "error",
    showClose: true,
  });
};

/**
 * @description notify 消息提示
 * @param {*} that this
 * @param {String} msg 提示文字
 * @param {String} title 提示标题
 */
export const infoNotify = (that, msg, title) => {
  let t = title ? title : DEFAULT_INFO_TITLE;
  let m = msg ? msg : DEFAULT_INFO_MSG;
  that.$notify.info({
    title: t,
    message: m,
    showClose: true,
  });
};

/**
 * @description message 消息提示
 * @param {*} that this
 * @param {String} msg 提示文字
 */
export const infoMsg = (that, msg) => {
  let m = msg ? msg : DEFAULT_INFO_MSG;
  that.$message({
    message: m,
    showClose: true,
  });
};

/**
 * @description notify 警告提示
 * @param {*} that this
 * @param {String} msg 提示文字
 * @param {String} title 提示标题
 */
export const warningNotify = (that, msg, title) => {
  let t = title ? title : DEFAULT_WARNING_TITLE;
  let m = msg ? msg : DEFAULT_WARNING_MSG;
  that.$notify({
    title: t,
    message: m,
    type: "warning",
    showClose: true,
  });
};

/**
 * @description message 警告提示
 * @param {*} that this
 * @param {String} msg 提示文字
 */
export const warningMsg = (that, msg) => {
  let m = msg ? msg : DEFAULT_WARNING_MSG;
  that.$message({
    message: m,
    type: "warning",
    showClose: true,
  });
};

/**
 * @description 无操作弹窗操作提示
 * @param {*} that this
 * @param {String} msg 提示文字
 * @param {String} title 提示标题
 */
export const confirmWory =  (that, msg, title) => {
  let t = title ? title : DEFAULT_INFO_TITLE;
  let m = msg ? msg : DEFAULT_INFO_MSG;
  that.$confirm(m, t, {
      type: "warning",
      closeOnClickModal: false,
      closeOnPressEscape: false,
      showCancelButton: false
  });
}

/**
 * 操作弹窗提示设置
 */
export const confirmSet = {
  type: "warning",
  closeOnClickModal: false,
  closeOnPressEscape: false,
  offset: 800
}