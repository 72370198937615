<template>
  <div id="DialogComponent">
    <el-dialog custom-class="dialog_wrapper" width="600px" :title="title" :visible.sync="dialogVisible" :before-close="handleClose"
     :close-on-click-modal="false" :close-on-press-escape="false" append-to-body modal-append-to-body destroy-on-close>
      <slot name="Container"></slot>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'DialogComponent',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState("pageOptions", ["dialogVisible"])
  },
  methods: {
    handleClose() {
      this.$emit('handleCloseDialog', 'ruleForm');
    }
  }
}
</script>