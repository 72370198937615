<template>
  <!-- elementUI 分页器 -->
  <div id="Pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageData.pageSize"
      :page-size="pageData.pageRows"
      :layout="layout"
      :total="total"
      :background="background"
      :page-sizes="pageSizes"
      prev-text="上一页"
      next-text="下一页"
      :hide-on-single-page="hideOnSinglePage"
    >
      <!-- 
        hide-on-single-page 无数据时隐藏分页器
      -->
    </el-pagination>
  </div>
</template>
  
  <script>
export default {
  name: "PaginationComponent",
  props: {
    // 页面条数下拉
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40];
      },
    },
    // 分页条布局
    layout: {
      type: String,
      // default: "total, sizes, prev, pager, next, jumper",
      default: "prev, pager, next",
    },
    // 是否显示分页条背景
    background: {
      type: Boolean,
      default: true,
    },
    // 页面总条数
    total: {
      type: Number,
      default: 0,
    },
    // 分页数据（当前页/每页展示数据条数）
    pageData: {
      type: Object,
      default: () => {
        return {
          pageSize: 1,
          pageRows: 10,
        };
      },
    },
    // 查询分页数据方法
    getTableList: {
      type: Function,
    },
    hideOnSinglePage: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSizeChange(val) {
      // 修改每页数据条数
      this.$set(this.pageData, "pageRows", val);
      // 刷新列表数据
      this.$emit("getPage");
    },
    handleCurrentChange() {
      // 刷新列表数据
      this.$emit("getPage");
    }
  }
};
</script>
  
<style lang="less" scoped>
#Pagination {
  margin-right: 20px;
  // margin-top: 30px;
  display: flex;
  justify-content: center;

  .el-pagination {
    border: 1px solid var(--border-color);
    padding: 0 5px;
    border-radius: 6px;
  }

  ::v-deep(.el-pagination.is-background) {
    .btn-prev {
      border-right: 1px solid var(--border-color);
    }

    .btn-prev,
    .btn-next {
      margin: 0;
      padding: 0 10px;
      background-color: #fff;
    }

    .el-pager li {
      background-color: #fff;
      border-right: 1px solid var(--border-color);
      min-width: 40px;
      margin: 0;

      &:not(.disabled).active {
        background-color: var(--text-color-orange);
      }

      &:hover:not(.active) {
        color: var(--text-color-orange);
      }
    }
  }
}
</style>
  