/**
 * 自定义本地缓存方法
 */
import commonParam from "@/param/commonParam";

/**
 * localStorage 存入本地缓存
 * @param { String } key 
 * @param { * } data 
 */
export function setData(key, data) {
  let tk = transKey(key);
  try {
    localStorage.setItem(tk, JSON.stringify(data));
  } catch (e) {
    localStorage.setItem(tk, data);
  }
}

/**
 * localStorage 读取本地缓存
 * @param { String } key 
 * @returns {*}
 */
export function getData(key) {
  let tk = transKey(key);
  let jsonStr = localStorage.getItem(tk);
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    return jsonStr;
  }
}

/**
 * localStorage 删除指定本地缓存
 * @param { String } key 
 */
export function delData(key) {
  let tk = transKey(key);
  localStorage.removeItem(tk);
}

/**
 * localStorage 清空本地缓存
 */
export function clearData() {
  localStorage.clear();
}

/**
 * 编辑键名
 * @param {String} key 
 * @returns {String}
 */
function transKey (key) {
  return commonParam.systemName + "." + key;
}