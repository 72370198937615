<template>
  <div class="loacl_container">
    <div class="wrapper">
      <div class="home" @click="toHome">首页</div>
      <div class="">
        <span class="icon">{{ ">" }}</span>
        <span
          :class="{ active: !isDetail, pointer: isDetail }"
          @click="toBack"
          >{{ local }}</span
        >
      </div>
      <div v-if="isDetail" class="">
        <span class="icon">{{ ">" }}</span>
        <span :class="{ active: isDetail }">{{ _subTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    local: {
      type: String,
      default: "",
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    detailMsg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fromPath: "/",
    };
  },
  computed: {
    _subTitle() {
      this.fromPath += this.detailMsg;
      let ret = "";
      switch (this.detailMsg) {
        case "servers":
          ret = "服务详情";
          break;
        case "serversPreview":
          ret = "服务详情（预览）";
          break;
        case "digitalize":
            ret = "智改数转详情";
            break;
        case "activity":
          ret = "活动详情";
          break;
        case "experts":
          ret = "专家详情";
          break;
        case "provider":
          ret = "机构详情";
          break;
        case "demand":
          ret = "需求详情";
          break;
        case "caseCenter":
          ret = "案例详情";
          break;
        case "supplyDemand":
          ret = "供需详情";
          break;
        default:
          ret = "";
          break;
      }
      return ret;
    },
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toBack() {
      if (!this.isDetail) return;
      this.$router.push(this.fromPath);
    },
  },
};
</script>

<style lang="less" scoped>
.loacl_container {
  height: 37px;
  line-height: 37px;
  color: var(--color-info);
  background-color: #fff;
  margin-bottom: 25px;

  .wrapper {
    font-size: 16px;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 15px;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 5px;
      height: 100%;
      background-color: var(--text-color-orange);
      border-radius: 6px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .home {
    // margin-left: 15px;

    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #000;
    }
  }

  .icon {
    margin: 0 10px;
    color: #b3b3b1;
  }

  .pointer {
    transition: color 0.3s;

    &:hover {
      color: #000;
    }
  }

  .active {
    color: var(--text-color-orange);
    font-weight: 600;
  }
}
</style>