const commonParam = {
  // 系统名称
  systemName: "yqy-portal",
  // 服务器路径
  // serverUrl: "http://127.0.0.1:26000",
  // serverUrl: "http://192.168.1.100:26000",
  // serverUrl: "http://192.168.1.214:25000",
  serverUrl: 'https://api3.smesc.cn',
  // 图片地址接口地址
  // showImgUrl: 'http://192.168.1.212/smesc',
  showImgUrl: 'https://eps.smesc.cn/img',
  //注册地址
  // registerUrl: 'http://127.0.0.1:26000',
  registerUrl: 'https://api3.smesc.cn',
  //后台管理端地址
  // adminUrl: "http://127.0.0.1:8089/#/optionModule?rk=",
  adminUrl: "https://yqszadmin.smesc.cn/#/optionModule?rk=",
  //密码加密公钥
  getPublicKeyUrl: "/uaa/public-key",
  // 刷新token地址
  refreshTokenUrl: '/uaa/authLogin',
  tokenOutTime: 7140 * 1000,
  refreshTokenOutTime: 259140 * 1000,
  tokenStorageName: "yqy-portal.tokenInfo",
  tokenStorageTime: "yqy-portal.tokenStorageTime",
  // 文件下载路径
  downloadUrl: '/download',
  // 操作方式
  handleType: {
    addType: 'ADD',
    updateType: 'UPDATE',
    lookType: 'LOOK'
  },
  headerName: "益企云科技股份有限公司",
  // 首页左上角名字
  tabName: '益企云科技股份有限公司',
  // 登录页面名字
  loginPlatformName: '益企云科技股份有限公司',
  // 公司名称
  systemCompany: '益企云科技股份有限公司',
  // 公司联系电话
  systemTel: "028-83228797", // 028-962555
  // 备案号
  systemRecord: '蜀ICP备17040581号-1',
  systemRecordPath: 'https://beian.miit.gov.cn/',
  address: '成都市金牛区成华街5号',
  // 指导单位
  guidingUnit: '工业和信息化部',
  // 主管单位
  competentUnit: '四川省经济和信息化厅',
  // 系统脚下名字
  footerText: '系统版本v1.6&nbsp;&copy;&nbsp;2023&nbsp;<i class="fa fa-fw fa-phone"></i>028-962555',
  // 限制接口权限页面展示,目前国土项目不需要接口细粒度控制，
  flgBtn: false,
  // 注册跳转地址
  loginPath: 'https://pltf.smesc.cn/register#/login',
  registerPath: 'https://pltf.smesc.cn/register#/register',
  findPasswordPath: 'https://pltf.smesc.cn/register#/findPassword',
  smescPath: 'https://www.smesc.cn',
};

export default commonParam;
