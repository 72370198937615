import { nanoid } from "nanoid";

const applicationList = {
  menuList: [
    // { id: nanoid(), title: "政策辅导", path: "https://zc.smesc.cn/", name: "PolicyView", target: true, unUse: true },
    { id: nanoid(), title: "智改数转", path: "/digitalize", name: "DigitalizeView", target: false },
    { id: nanoid(), title: "数据服务", path: "/servers", name: "ServersView", target: false },
    { id: nanoid(), title: "服务需求", path: "/demand", name: "DemandView", target: false },
    // { id: nanoid(), title: "热门培训", path: "/", name: "CultivateView", target: false, unUse: true },
    { id: nanoid(), title: "企服活动", path: "/activity", name: "ActivityView", target: false },
    // { id: nanoid(), title: "供需大厅", path: "/supplyDemand", name: "SupplyDemand", target: false },
  ],
  dropdownList: [
    { id: nanoid(), label: '个人中心', value: 'userCenter', path: '/userCenter', icon: 'fa-user-circle-o', divided: false, isUse: false },
    { id: nanoid(), label: '优惠券', value: 'coupon', path: '/coupon', icon: 'fa-ticket', divided: false, isUse: false },
    { id: nanoid(), label: '购物车', value: 'shopCart', path: '/shopCart', icon: 'fa-shopping-cart', divided: false, isUse: true },
    { id: nanoid(), label: '退出登录', value: 'logout', icon: 'fa-sign-out', divided: false, isUse: true }
  ],
  footerLink: [
    { id: nanoid(), name: '网站首页', path: '/', isUse: true },
    { id: nanoid(), name: '关于我们', path: '', isUse: false },
    { id: nanoid(), name: '联系方式', path: '', isUse: false },
    { id: nanoid(), name: '免责条款', path: '', isUse: false },
    { id: nanoid(), name: '版权隐私', path: '', isUse: false },
    { id: nanoid(), name: '网站地图', path: '', isUse: false },
    { id: nanoid(), name: '网站留言', path: '', isUse: false }
  ],
  bannerTab: [
    { id: nanoid(), title: '服务活动', value: 'activity', isUse: true },
    { id: nanoid(), title: '最新申报', value: 'zxtt', isUse: true },
    { id: nanoid(), title: '热门服务', value: 'servers', isUse: true },
    // { id: nanoid(), title: '明星服务商', value: 'provider', isUse: true },
    // { id: nanoid(), title: '平台公告', value: 'ptgg', isUse: false },
  ],
  /* 友情链接 */
  // 国家部委
  centralRange: [
    { id: nanoid(), value: 'samr', label: "国家市场监督管理总局", webset: 'https://www.samr.gov.cn/' },
    { id: nanoid(), value: 'mofcom', label: "中华人民共和国商务部", webset: 'http://www.mofcom.gov.cn/' },
    { id: nanoid(), value: 'ndrc', label: "国家发展和改革委员会", webset: 'https://www.ndrc.gov.cn/' },
    { id: nanoid(), value: 'most', label: "中华人民共和国科学技术部", webset: 'https://www.most.gov.cn/index.html' },
    { id: nanoid(), value: 'mof', label: "中华人民共和国财政部", webset: 'http://www.mof.gov.cn/index.htm' },
    { id: nanoid(), value: 'miit', label: "工业和信息化部", webset: 'https://www.miit.gov.cn/' },
  ],
  // 省厅局
  provinceRange: [
    { id: nanoid(), value: 'fgw', label: "四川省发展和改革委员会", webset: 'http://fgw.sc.gov.cn/' },
    { id: nanoid(), value: 'kjt', label: "四川省科学技术厅", webset: 'https://kjt.sc.gov.cn/' },
    { id: nanoid(), value: 'czt', label: "四川省财政厅", webset: 'http://czt.sc.gov.cn/' },
    { id: nanoid(), value: 'jxt', label: "四川省经济和信息化厅", webset: 'https://jxt.sc.gov.cn/' },
  ],
  // 行业协会
  industryRange: [
    { id: nanoid(), value: 'sme', label: "中国中小企业信息网", webset: 'https://www.sme.com.cn/' },
    { id: nanoid(), value: 'beijing', label: "北京", webset: 'https://www.smebj.cn/ncms/index/index.html' },
    { id: nanoid(), value: 'tianjin', label: "天津", webset: 'http://www.smetj.com/' },
    { id: nanoid(), value: 'henan', label: "河南", webset: 'http://www.hnqyfw.com/' },
    { id: nanoid(), value: 'hebei', label: "河北", webset: 'http://gxt.hebei.gov.cn/sme/' },
    { id: nanoid(), value: 'neimenggu', label: "内蒙古", webset: 'http://www.nmgsme.cn/' },
    { id: nanoid(), value: 'jilin', label: "吉林", webset: 'http://www.smejl.cn/' },
    { id: nanoid(), value: 'shanghai', label: "上海", webset: 'http://www.ssme.sh.gov.cn/' },
    { id: nanoid(), value: 'jiangsu', label: "江苏", webset: 'http://www.jssme.org.cn/' },
    { id: nanoid(), value: 'zhejiang', label: "浙江", webset: 'https://zj87.jxt.zj.gov.cn/' },
    { id: nanoid(), value: 'anhui', label: "安徽", webset: 'http://www.ahcycx.com/' },
    { id: nanoid(), value: 'fujian', label: "福建", webset: 'http://www.fujiansme.com/' },
    { id: nanoid(), value: 'shandong', label: "山东", webset: 'http://www.smesd.com.cn/' },
    { id: nanoid(), value: 'hubei', label: "湖北", webset: 'http://www.hbsme.com.cn/' },
    { id: nanoid(), value: 'hunan', label: "湖南", webset: 'http://www.smehn.cn/' },
    { id: nanoid(), value: 'guangdong', label: "广东", webset: 'http://www.968115.cn/' },
    { id: nanoid(), value: 'hainan', label: "海南", webset: 'http://218.77.183.4/hisme/' },
    { id: nanoid(), value: 'sichaun', label: "四川", webset: 'http://www.smesc.cn/' },
    { id: nanoid(), value: 'chongqing', label: "重庆", webset: 'https://www.cqsme.cn/' },
    { id: nanoid(), value: 'yunnan', label: "云南", webset: 'http://www.ynsmes.cn/' },
    { id: nanoid(), value: 'guizhou', label: "贵州", webset: 'http://sme.gxt.guizhou.gov.cn/' },
    { id: nanoid(), value: 'shanxi', label: "陕西", webset: 'http://www.smeshx.org.cn/' },
    { id: nanoid(), value: 'qinghai', label: "青海", webset: 'http://www.smeqh.com/' },
    { id: nanoid(), value: 'ningxia', label: "宁夏", webset: 'http://www.smenx.com.cn/' },
    { id: nanoid(), value: 'lanzhou', label: "兰州", webset: 'http://gxj.lanzhou.gov.cn:8820/' },
    { id: nanoid(), value: 'liuzhou', label: "柳州", webset: 'https://www.smelz.com.cn/index.html' },
    { id: nanoid(), value: 'xinjiang', label: "新疆", webset: 'http://www.xjsmefw.cn/www/index.html' },
    { id: nanoid(), value: 'hefei', label: "合肥", webset: 'http://www.hfsmesc.com/' },
    { id: nanoid(), value: 'wuhan', label: "武汉", webset: 'http://www.whsme.net.cn/' },
    { id: nanoid(), value: 'tianshui', label: "天水", webset: 'http://ts.gs96871.com/' },
    { id: nanoid(), value: 'xiamen', label: "厦门", webset: 'http://www.xmsme.com/' },
    { id: nanoid(), value: 'haerbin', label: "哈尔滨", webset: 'http://www.smehrb.com.cn/' },
    { id: nanoid(), value: 'heilongjiang', label: "黑龙江", webset: 'http://www.smehlj.org.cn/' },
    { id: nanoid(), value: 'qingdao', label: "青岛", webset: 'http://www.smeqd.com/' },
  ],
  // 合作平台
  platformsRange: [
    { id: nanoid(), value: 'yiqicloud', label: "益企云", webset: 'https://www.yiqicloud.com.cn/#/' },
  ],
}

export default applicationList;