<template>
  <div id="FilterComponent">
    <div v-if="type" class="filter_container">
      <div v-if="type.includes('type')" class="label-wrapper">
        <div class="label-title">{{ serviceLabel }}：</div>
        <div class="label-item">
          <p :class="{active: filterParams.serviceTypeCode === ''}" @click="handleFilter('', 'serviceTypeCode')">不 限</p>
          <p :class="{active: filterParams.serviceTypeCode === item.svcCode}" v-for="item in serviceList" :key="item.svcCode" @click="handleFilter(item.svcCode, 'serviceTypeCode')">{{ item.svcName }}</p>
        </div>
      </div>
      <div v-show="subTypeList.length" class="label-wrapper">
        <div class="label-title">子类类型：</div>
        <div class="label-item">
          <p :class="{active: filterParams.serviceSubTypeCode === ''}" @click="handleFilter('', 'serviceSubTypeCode')">不 限</p>
          <p :class="{active: filterParams.serviceSubTypeCode === item.svcCode}" v-for="item in subTypeList" :key="item.svcCode" @click="handleFilter(item.svcCode, 'serviceSubTypeCode')">{{ item.svcName }}</p>
        </div>
      </div>
      <div v-if="type.includes('area')" class="label-wrapper">
        <div class="label-title">所在地区：</div>
        <div class="label-item">
          <p :class="{active: filterParams.districtCode === ''}" @click="handleFilter('', 'districtCode')">全 部</p>
          <p :class="{active: filterParams.districtCode === item.districtCode}"
            v-for="item in regionTree" :key="item.id" @click="handleFilter(item.districtCode, 'districtCode')">
            {{ item.districtName }}
          </p>
        </div>
      </div>
    </div>
    <div class="search_container">
      <div class="search-wrapper">
        <div class="search-key">
          <p>服务<span>{{ searchTitle }}</span></p>
          <img src="@/assets/img/icon/icon-21.png" alt="">
        </div>
        <div class="input-wrapper">
          <el-input v-model.trim="filterParams.searchKey" placeholder="请输入搜索关键字" clearable></el-input>
          <div class="iconfont icon-search" @click="handleSearch"></div>
        </div>
      </div>
      <div class="sort-wrapper">
        <!-- <div v-if="type.includes('hot')" class="hot_sort" @click="handleChangeSort('hotSort')">
          热门<i class="fa" :class="filterParams.hotSort ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i>
        </div>
        <div v-if="type.includes('price')" class="price_sort" @click="handleChangeSort('priceSort')">
          价格<i class="fa" :class="filterParams.priceSort ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i>
        </div> -->
        <div class="total">共为您找到<span>{{ total }}</span>个相关信息</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { deepClone } from '@/utils/dataUtil';
export default {
  name: "FilterComponent",
  props: {
    type: {
      type: String,
      default: 'type,area,hot,price'
    },
    serviceLabel: {
      type: String,
      default: '服务类型'
    },
    searchTitle: {
      type: String,
      default: '超市'
    },
    total: {
      type: Number,
      default: 0
    },
    serviceList: {
      type: Array,
      default: () => {
        return []
      }
    },
    paramsData: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  data () {
    return {
      subclassList: [],
      /* filterObj: {
        searchKey: '', // 搜索关键字
        serverType: '', // 服务类型
        subclassType: '', // 服务类型子类型
        disCode: '', // 所在地区
        hotSort: false, // 热门排序
        priceSort: false, // 价格排序
      }, */
      filterParams: {
        searchKey: '',
        serviceTypeCode: '',
        serviceSubTypeCode: '',
        districtCode: ''
      },
      subTypeList: [],
      regionTree: [],
    }
  },
  computed: {
    ...mapState('pageOptions', ['districtList'])
  },
  methods: {
    /* 初始化数据 */
    init() {
      this.initFilterData()
    },
    /* 初始化筛选数据 */
    initFilterData() {
      this.regionTree = this.districtList[0].child;
    },
    /* 点击筛选 */
    handleFilter(val, key) {
      if (key === 'serviceTypeCode') {
        this.filterParams.serviceSubTypeCode = '';
        if (val) {
          const result = this.serviceList.find(item => item.svcCode === val);
          this.subTypeList = result.child;
        } else {
          this.subTypeList = [];
        }
      }
      this.filterParams[key] = val;
      this.handleSearch();
    },
    /* 重置 */
    handleReset() {
      this.filterParams = {
        searchKey: '',
        serviceTypeCode: '',
        serviceSubTypeCode: '',
        districtCode: ''
      };
      this.subTypeList = [];
      this.handleSearch();
    },
    /* 搜索 */
    handleSearch() {
      const obj = deepClone(this.filterParams);
      // console.log(obj);
    }
  },
  created () {
    this.init();
  },
  watch: {
    serviceTypeCode(newValue, oldValue) {
      if (newValue) {
        this.filterParams.serviceTypeCode = newValue
        const result = this.serviceList.find(item => item.svcCode === newValue)
        this.subTypeList = result.child
      }
    }
  }
};
</script>

<style lang="less" scoped>
#FilterComponent {
  margin-bottom: 30px;

  .filter_container {
    box-sizing: border-box;
    border: 1px solid var(--border-color-extra-light);
    box-shadow: var(--box-shadow-light-3);
    padding: 15px 10px 10px;
    margin-bottom: 15px;
    border-radius: 6px;

    .label-wrapper {
      display: flex;
      align-items: baseline;

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      .label-title {
        flex-shrink: 0;
        color: var(--color-info);
      }

      .label-item {
        display: flex;
        flex-wrap: wrap;

        p {
          border: 1px solid transparent;
          padding: 3px 10px;
          border-radius: 30px;
          box-sizing: border-box;
          margin: 0 5px 5px;
          cursor: pointer;
          transition: .3s;

          &.active {
            border-color: var(--text-color-orange);
            background-color: #feeae3;
            color: var(--text-color-orange);
          }

          &:hover {
            border-color: var(--text-color-orange);
            background-color: #feeae3;
            color: var(--text-color-orange);
          }
        }
      }
    }
  }

  .search_container {
    box-sizing: border-box;
    border: 1px solid var(--border-color-extra-light);
    box-shadow: var(--box-shadow-light-3);
    padding: 15px 10px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;

    .search-wrapper {
      display: flex;
      align-items: center;

      .search-key {
        padding-left: 10px;
        font-size: 24px;
        font-weight: 600;
        margin-right: 15px;
        position: relative;

        span {
          color: var(--text-color-orange);
        }

        img {
          width: 17px;
          height: 16px;
          position: absolute;
          left: 0;
          top: -20%;
        }
      }

      .input-wrapper {
        display: flex;
        align-items: center;
        border: 1px solid var(--border-color);
        border-radius: 30px;
        padding-right: 10px;
        overflow: hidden;

        .iconfont {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          color: #0d67d5;
          font-weight: 600;
          cursor: pointer;
        }

        ::v-deep(.el-input__inner) {
          border: none;
        }
      }
    }

    .sort-wrapper {
      display: flex;
      align-items: center;

      .hot_sort {
        margin-right: 40px;
        cursor: pointer;

        i {
          margin-left: 5px;
        }
      }

      .price_sort {
        margin-right: 60px;
        cursor: pointer;

        i {
          margin-left: 5px;
        }
      }

      .total {
        span {
          color: #e92823;
          margin: 0 5px;
        }
      }

    }
  }
}
</style>