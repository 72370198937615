<template>
  <el-skeleton class="list_item">
    <template slot="template">
      <el-skeleton-item class="img" variant="image" />
      <div class="content">
        <el-skeleton-item variant="p" />
        <div class="label">
          <el-skeleton-item class="label-text" variant="text" />
          <el-skeleton-item class="label-text" variant="text" />
        </div>
        <el-skeleton-item variant="p" style="width: 50%;" />
        <el-skeleton-item variant="p" />
      </div>
    </template>
  </el-skeleton>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.list_item {
  margin-bottom: 40px;
  border: 1px solid var(--border-color-extra-light);
  border-radius: 6px;
  overflow: hidden;

  .img {
    height: 220px;
  }

  .content {
    padding: 15px 20px;
    line-height: 2;

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      &-text {
        width: 45%;
      }
    }
  }
}
</style>