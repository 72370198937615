/**
 * @description 对象/数组深度拷贝
 * @param {Object | Array} _obj 目标对象/数组
 * @returns
 */
export function deepClone(_obj) {
  let objClone = Array.isArray(_obj) ? [] : {};
  if (_obj && typeof _obj === "object") {
    for (let key in _obj) {
      if (_obj[key] && typeof _obj[key] === "object") {
        objClone[key] = deepClone(_obj[key]);
      } else {
        objClone[key] = _obj[key];
      }
    }
  }
  return objClone;
}

/**
 * @description 对象深度合并
 * @param {Object} target 目标对象
 * @param {Object} source 合并对象
 * @returns {Object}
 */
export function deepAssign(target = {}, source = {}) {
  target = deepClone(target);
  if (typeof target !== 'object' || typeof source !== 'object') return false;
  for (let prop in source) {
    if (!source.hasOwnProperty(prop)) continue;
    if (prop in target) {
      if (typeof target[prop] !== 'object') {
        target[prop] = source[prop];
      } else {
        if (typeof source[prop] !== 'object') {
          target[prop] = source[prop];
        } else {
          if (target[prop].concat && source[prop].concat) {
            target[prop] = target[prop].concat(source[prop]);
          } else {
            target[prop] = deepAssign(target[prop], source[prop]);
          }
        }
      }
    } else {
      target[prop] = source[prop];
    }
  }
  return target;
}

/**
 * @description 删除对象中值为空的键名
 * @param {Object} val 
 * @returns {Object}
 */
export function delNullKey(val) {
  let obj = deepClone(val);
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "" || typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * @description 将对象转化为URL参数
 * @param {Object} data
 * @returns {String}
 */
export function queryParams(data = {}) {
  let prefix = '?';
  let _result = [];
  for (let key in data) {
    let value = data[key];
    // 去掉为空的参数
    if (['', undefined, null].indexOf(value) >= 0) {
      continue;
    }
    // 如果值为数组，另行处理
    // e.g. {ids: [1, 2, 3]}
    if (value.constructor === Array) {
      let commaStr = "";
      value.forEach(_value => {
        commaStr += (commaStr ? "," : "") + _value;
      })
      // 结果: ids=1,2,3
      _result.push(key + '=' + commaStr);
    } else {
      _result.push(key + '=' + value);
    }
  }
  return _result.length ? prefix + _result.join('&') : '';
}

/**
 * @description 号码添加星号
 */
export function noPassByMobile(str) {
  if (str != null && str != undefined && str != "") {
    return str.substr(0, 3) + "****" + str.substr(-3);
  } else {
    return "";
  }
}